<script setup>
import { SearchBar } from '@/components/searchbar';
import { Run } from '@/components/threads'
import { Loading } from '@/components';
import { useThread } from '@/composables'

const { thread, isLoading } = useThread()
</script>

<template>
    <div v-if="!isLoading && thread"
        class="flex flex-col w-full max-w-4xl lg:w-screen xl:max-w-[90%] xl:min-w-[90%] 2xl:max-w-[80%] 2xl:min-w-[80%] grid grid-cols-8 justify-center mx-auto">
        <div class="col-span-8 grid grid-cols-12 justify-center mx-6 py-4">
            <Run v-for="(run, i) in thread?.runs" :key="i" :run="run" :i="i" :threadId="thread.id"
                :isLast="i == thread?.runs.length - 1" class="col-span-12" />
            <div v-if="thread?.runs.length > 0" class="sticky bottom-4 sm:col-span-8 col-span-12">
                <SearchBar v-model="thread.options" size="sm" ctx="thread" :id="thread.id" />
            </div>
        </div>
    </div>
    <Loading v-else />
</template>